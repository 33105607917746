import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Normalize } from 'styled-normalize'
import { ThemeProvider } from 'styled-components'

import App from 'App'
import store from 'redux/store'
import theme from 'styles/theme'
import GlobalStyle from 'styles/global'

ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<Normalize />
			<GlobalStyle />
			<App />
		</ThemeProvider>
	</Provider>,
	document.getElementById('root')
)
