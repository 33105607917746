import MenuLink from 'components/MenuLink'
import Companies from 'components/Companies'
import { connect } from 'react-redux'
import { toggleDropdownMenuVisibility } from 'redux/actions'
import ClickAwayListener from 'react-click-away-listener'
import styled from 'styled-components'
import { RefObject } from 'react'

type DispatchProps = {
	toggleDropdownMenuVisibility: () => void
}

interface IDropdownProps {
	buttonRef: RefObject<HTMLDivElement>
}

interface IPosition {
	top: string | number
	right: string | number
}

export const DropdownMenu = ({
	toggleDropdownMenuVisibility,
	buttonRef,
}: DispatchProps & IDropdownProps) => {
	let position: IPosition = { top: `0`, right: `0` }

	/*
	 * Can be implemented via css with for simplifying
	 * parent{ position: relative }
	 * but sometimes it's better to use ref positioning
	 * */

	if (buttonRef && buttonRef.current) {
		const { offsetLeft, offsetWidth, offsetTop, offsetHeight } = buttonRef.current
		position.top = offsetHeight + offsetTop
		position.right = window.innerWidth - offsetLeft - offsetWidth
	}

	return (
		<>
			<ClickAwayListener
				onClickAway={event => {
					if (buttonRef.current?.contains(event.target as Node)) return
					toggleDropdownMenuVisibility()
				}}
			>
				<StyledDropdownMenu $position={position}>
					<Companies />
					<div>
						<MenuLink text="Get the mobile app" icon="phone_iphone" />
						<MenuLink text="Community" icon="people" />
						<MenuLink text="Knowledge base" icon="book" />
					</div>

					<div>
						<MenuLink text="Settings" icon="settings" />
						<MenuLink text="Log out" icon="exit_to_app" />
					</div>
				</StyledDropdownMenu>
			</ClickAwayListener>
		</>
	)
}

const StyledDropdownMenu = styled.div<{
	$position: IPosition
}>`
	min-width: 303px;
	position: absolute;
	top: ${({ $position }) => $position.top}px;
	right: ${({ $position }) => $position.right}px;
	box-shadow: 0 2px 8px ${({ theme }) => theme.elevationLevel2};
	border-radius: 6px;
	border: 1px solid ${({ theme }) => theme.grey3};
	will-change: transform, opacity;

	> div {
		&:not(:last-child) {
			border-bottom: 1px solid ${({ theme }) => theme.grey3};
		}

		&:not(:first-child) {
			padding: 6px 0;
		}
	}

	animation-duration: 100ms;
	animation-fill-mode: both;
	animation-name: fadeInDown;
	@keyframes fadeInDown {
		0% {
			opacity: 0;
			transform: translate3d(0, -10px, 0);
		}
		100% {
			opacity: 1;
			transform: none;
		}
	}
`

export default connect(null, {
	toggleDropdownMenuVisibility,
})(DropdownMenu)
