import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import reducer from 'redux/reducer'
import { ReduxState } from 'types/types'

const initialState: ReduxState = {
	isDropdownMenuVisible: false,
	selectedCompanyId: 1,
	companiesLoading: false,
	companies: [],
}

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))

const store = createStore(reducer, initialState, composedEnhancer)

export default store
