import styled, { css, StyledProps } from 'styled-components'
import { connect } from 'react-redux'
import { toggleDropdownMenuVisibility } from 'redux/actions'
import { rgba } from 'polished'

type DispatchProps = {
	toggleDropdownMenuVisibility: () => void
}
type Props = {
	text: string
	icon: string
}

/*
 * I prefer to use svg icons instead of fontIcons
 * */
export const MenuLink = ({ icon, text, toggleDropdownMenuVisibility }: Props & DispatchProps) => (
	<StyledMenuLink onClick={toggleDropdownMenuVisibility} $icon={icon}>
		<i className="material-icons">{icon}</i>
		{text}
	</StyledMenuLink>
)

interface IStyledStyledMenuLink {
	$icon: string
}
const dependOnIconStyles = ({ $icon, theme }: StyledProps<IStyledStyledMenuLink>) => {
	if ($icon === 'exit_to_app')
		return css`
			color: ${theme.red};

			&:hover,
			&:focus {
				background-color: ${({ theme }) => rgba(theme.red, 0.1)};
			}

			&:active {
				background-color: ${({ theme }) => rgba(theme.red, 0.2)};
			}
		`
}

const StyledMenuLink = styled.div<IStyledStyledMenuLink>`
	user-select: none;
	display: grid;
	gap: 8px;
	grid-template-columns: 20px 1fr;
	padding: 8px 16px;
	font-size: 16px;
	align-items: center;
	line-height: 125%;

	> i {
		text-align: center;
		font-size: 20px;
		display: block;
	}

	cursor: pointer;
	transition: 100ms background-color;
	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.grey1};
	}

	&:active {
		background-color: ${({ theme }) => theme.grey2};
	}

	${props => dependOnIconStyles(props)}
`

export default connect(null, {
	toggleDropdownMenuVisibility,
})(MenuLink)
