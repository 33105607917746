import { Company, ReduxState } from 'types/types'
import { connect } from 'react-redux'
import { setSelectedCompanyId, toggleDropdownMenuVisibility } from 'redux/actions'
import styled, { css, StyledProps } from 'styled-components'
import { FC } from 'react'
import { isCompanySelected } from 'redux/selectors'
import { createSelector } from 'reselect'
import { rgba } from 'polished'

type DispatchProps = {
	setSelectedCompanyId: (id: Company['id']) => void
	toggleDropdownMenuVisibility: () => void
}

type ReduxProps = {
	isCompanySelected?: boolean
}

interface IStyledCompanyLink {
	$active?: boolean
}

export const CompanyLink: FC<Company & DispatchProps & ReduxProps> = ({
	name,
	id,
	setSelectedCompanyId,
	isCompanySelected,
	toggleDropdownMenuVisibility,
}) => {
	return (
		<StyledCompanyLink
			onClick={() => {
				if (isCompanySelected) return
				toggleDropdownMenuVisibility()
				setSelectedCompanyId(id)
			}}
			data-test-company-link
			$active={isCompanySelected}
		>
			{name}
			{isCompanySelected && <span className="material-icons-outlined">done</span>}
		</StyledCompanyLink>
	)
}

const StyledCompanyLink = styled.li<IStyledCompanyLink>`
	cursor: pointer;
	user-select: none;
	padding: 12px 16px;
	font-weight: bold;
	font-size: 16px;
	line-height: 125%;
	transition: 100ms background-color;
	display: grid;
	grid-template-columns: 1fr 16px;
	align-items: center;
	${props => dependOnActiveStyles(props)}
`

const dependOnActiveStyles = ({ $active, theme }: StyledProps<IStyledCompanyLink>) => {
	if ($active) {
		return css`
			background-color: ${rgba(theme.green, 0.1)};
			color: ${({ theme }) => theme.darkGreen};
			span {
				font-size: 18px;
				height: 18px;
				transform: translateX(5px);
			}

			&:hover,
			&:focus {
				background-color: ${rgba(theme.green, 0.2)};
			}
		`
	}
	return css`
		color: ${({ theme }) => theme.dark};
		&:hover,
		&:focus {
			background-color: ${theme.grey1};
		}

		&:active {
			background-color: ${theme.grey2};
		}
	`
}

const makeMapStateToProps =
	() =>
	(state: ReduxState, { id, name }: ReduxProps & Company) => ({
		isCompanySelected: (() => createSelector([isCompanySelected], selected => selected))()(
			state,
			{
				company: {
					id,
					name,
				},
			}
		),
	})

export default connect(makeMapStateToProps, {
	setSelectedCompanyId,
	toggleDropdownMenuVisibility,
})(CompanyLink)
