import 'styled-components'

const theme = {
	dark: `#333`,

	grey6: `#717175`,
	grey5: `#8b8b8f`,
	grey4: `#b4b4b8`,
	grey3: `#dcdce0`,
	grey2: `#eeeef2`,
	grey1: `#f7f7f9`,

	white: `#fff`,
	black: `#000`,

	darkGreen: `#157123`,
	green: `#37a447`,
	lightGreen: `#40c353`,

	darkBlue: `#3f475e`,
	blue: `#305e95`,
	lightBlue: `#5787bf`,

	darkOrange: `#c14e04`,
	orange: `#e77b35`,
	lightOrange: `#eda538`,

	darkRed: `#af0419`,
	red: `#d33649`,
	lightRed: `#f22d45`,

	purple: `#835994`,

	brown: `#935f3a`,

	elevationLevel2: `rgba(51, 51, 51, 0.24)`,
}

type ITheme = {
	[variable in keyof typeof theme]: string
}
declare module 'styled-components' {
	export interface DefaultTheme extends ITheme {}
}

export default theme
