import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { ReduxState } from 'types/types'
import { getIsDropdownMenuVisible, selectedCompanyName, companiesLoading } from 'redux/selectors'
import { toggleDropdownMenuVisibility } from 'redux/actions'
import DropdownMenu from 'components/DropdownMenu'
import styled from 'styled-components'
import { useRef } from 'react'
import Skeleton from 'react-loading-skeleton'

type ReduxProps = {
	selectedCompanyName: string
	isDropdownMenuVisible: boolean
	companiesLoading: boolean
}

type DispatchProps = {
	toggleDropdownMenuVisibility: () => void
}

export const DropdownLink = ({
	selectedCompanyName,
	companiesLoading,
	isDropdownMenuVisible,
	toggleDropdownMenuVisibility,
}: ReduxProps & DispatchProps) => {
	const ref = useRef<HTMLDivElement>(null)
	return (
		<>
			<NavLink
				ref={ref}
				className={isDropdownMenuVisible ? 'active' : undefined}
				onClick={() => {
					toggleDropdownMenuVisibility()
				}}
				data-test-nav-link
			>
				<div className="textWrapper">
					<div className="text">Elon Musk</div>
					<div className="subtext">
						{companiesLoading ? (
							<Skeleton height={15} width={120} />
						) : (
							selectedCompanyName
						)}
					</div>
				</div>
				<i className="material-icons-outlined">settings</i>
			</NavLink>
			{isDropdownMenuVisible && <DropdownMenu buttonRef={ref} />}
		</>
	)
}
const NavLink = styled.div`
	user-select: none;
	display: grid;
	gap: 8px;
	grid-template-columns: 1fr 24px;
	align-items: center;
	max-width: 280px;
	padding: 8px;
	border-radius: 8px;
	cursor: pointer;
	transition: 100ms background-color;
	color: ${({ theme }) => theme.black};

	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.grey2};
	}

	&:active,
	&.active {
		background-color: ${({ theme }) => theme.grey3};
	}

	.textWrapper {
		text-align: right;
	}

	.text {
		font-weight: bold;
		padding-bottom: 2px;
	}

	.subtext {
		color: ${({ theme }) => theme.grey6};
	}
`

export default connect(
	createStructuredSelector<ReduxState, ReduxProps>({
		isDropdownMenuVisible: getIsDropdownMenuVisible,
		selectedCompanyName,
		companiesLoading,
	}),
	{ toggleDropdownMenuVisibility }
)(DropdownLink)
