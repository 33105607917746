import { combineReducers } from 'redux'
import { createReducer } from 'typesafe-actions'

import { Action, Company } from 'types/types'
import * as actions from 'redux/actions'

export const isDropdownMenuVisible = createReducer<boolean, Action>(false).handleAction(
	actions.toggleDropdownMenuVisibility,
	state => !state
)
export const companiesLoading = createReducer<boolean, Action>(false).handleAction(
	actions.setCompaniesLoading,
	(_, action) => action.payload
)

export const selectedCompanyId = createReducer<number | null, Action>(null).handleAction(
	actions.setSelectedCompanyId,
	(_, action) => action.payload
)

export const companies = createReducer<Array<Company>, Action>([]).handleAction(
	actions.setCompanies,
	(_, action) => action.payload
)

export default combineReducers({
	isDropdownMenuVisible,
	selectedCompanyId,
	companiesLoading,
	companies,
})
