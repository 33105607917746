import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
	
	html{
		font-family: Lato, "Lato", Helvetica, Arial, sans-serif;
		height: 100%;
		box-sizing: border-box;
	}

	body{
		font-size: 14px;
		color: ${({ theme }) => theme.dark};
	}

`

export default GlobalStyle
