import { ReduxState, Company } from 'types/types'

export const getCompanies = (state: ReduxState) => state.companies
export const companiesLoading = (state: ReduxState) => state.companiesLoading
export const getIsDropdownMenuVisible = (state: ReduxState) => state.isDropdownMenuVisible

/*
 * I prefer to use id only as props of this function,
 * but I decided not to modify it and leave it as it was originally.
 * */
export const isCompanySelected = (state: ReduxState, { company }: { company: Company }) =>
	state.selectedCompanyId === company.id

export const selectedCompanyName = ({ companies, selectedCompanyId }: ReduxState) => {
	let filteredCompaniesById = companies.filter(company => company.id === selectedCompanyId)
	if (!filteredCompaniesById.length) return 'Please select company'
	return filteredCompaniesById[0].name
}
