import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { ReduxState, Company } from 'types/types'
import { getCompanies, companiesLoading } from 'redux/selectors'

import CompanyLink from 'components/CompanyLink'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

type ReduxProps = {
	companies: Array<Company>
	companiesLoading: boolean
}

export const Companies = ({ companies, companiesLoading }: ReduxProps) => {
	const companiesList = companiesLoading ? (
		<SkeletonContainer>
			{[...Array(3)].map((_, index) => (
				<Skeleton key={index} height={35} />
			))}
		</SkeletonContainer>
	) : (
		companies.map(company => <CompanyLink key={company.id} {...company} />)
	)

	return (
		<StyledCompanies>
			<div>Your companies</div>
			<ul>{companiesList}</ul>
		</StyledCompanies>
	)
}

const SkeletonContainer = styled.div`
	padding: 0 16px 12px;
	gap: 6px;
	display: grid;
`

const StyledCompanies = styled.div`
	> div:first-of-type {
		user-select: none;
		padding: 12px 16px 4px;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 14px;
		line-height: 143%;
		color: ${({ theme }) => theme.grey6};
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
`

export default connect(
	createStructuredSelector<ReduxState, ReduxProps>({
		companies: getCompanies,
		companiesLoading,
	})
)(Companies)
