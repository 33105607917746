import styled from 'styled-components'
import DropdownLink from 'components/DropdownLink'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { setCompanies, setCompaniesLoading } from 'redux/actions'
import { Company } from 'types/types'

const App = ({
	setCompanies,
	setCompaniesLoading,
}: {
	setCompanies: (companies: Array<Company>) => void
	setCompaniesLoading: (value: boolean) => void
}) => {
	useEffect(() => {
		;(async () => {
			setCompaniesLoading(true)

			/*
			 * Use placeholder data for company list
			 * */
			const request = await fetch('https://jsonplaceholder.typicode.com/users?_limit=3')
			const response = await request.json()
			const users = response.map(
				(user: {
					id: number | string
					company: {
						name: string
					}
				}) => ({
					id: user.id,
					name: user.company.name,
				})
			)

			/*
			 * Simulated delay
			 * */
			setTimeout(() => {
				setCompanies(users)
				setCompaniesLoading(false)
			}, 6000)
		})()
	}, [setCompanies, setCompaniesLoading])
	return (
		<NavBar>
			<DropdownLink />
		</NavBar>
	)
}

const NavBar = styled.nav`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 16px;
`

export default connect(null, {
	setCompanies,
	setCompaniesLoading,
})(App)
